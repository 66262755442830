import { useEffect } from "react";
import { navigate } from "gatsby";

const Contact = () => {
  useEffect(() => {
    navigate("/schedule-demo");
  }, []);
  return null;
};

export default Contact;
